import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Sidebar.css";

interface MenuItem {
  route: string;
  icon: string;
  label: string;
}

const menuItems: MenuItem[] = [
  {
    route: "/allotments",
    icon: "/assets/icons/sidebar-icons/allotments.svg",
    label: "Allotments/Submissions",
  },
  {
    route: "/wallet-report",
    icon: "/assets/icons/sidebar-icons/wallet-report.svg",
    label: "Wallet Reports",
  },
  {
    route: "/wallet-individual-report",
    icon: "/assets/icons/sidebar-icons/job-card.svg",
    label: "Wallet Individual Reports",
  },
  {
    route: "/hub-efficiency-report",
    icon: "/assets/icons/sidebar-icons/hub-efficency-report.svg",
    label: "Hub Efficiency Reports",
  },
  {
    route: "/iot-report",
    icon: "/assets/icons/sidebar-icons/iot-report.svg",
    label: "IOT Reports",
  },
  {
    route: "/user-client-details",
    icon: "/assets/icons/sidebar-icons/user-client-details.svg",
    label: "User Client Details",
  },
  {
    route: "/ticketing",
    icon: "/assets/icons/sidebar-icons/ticketing.svg",
    label: "Ticketing",
  },
  {
    route: "/job-card",
    icon: "/assets/icons/sidebar-icons/job-card.svg",
    label: "Job Card",
  },
  {
    route: "/token-report",
    icon: "/assets/icons/sidebar-icons/token-report.svg",
    label: "Token Reports",
  },
  {
    route: "/pilot-nps-report",
    icon: "/assets/icons/sidebar-icons/tl-report.svg",
    label: "Pilot NPS Reports",
  },
  // {
  //   route: "/payout-automation",
  //   icon: "/assets/icons/sidebar-icons/payout-automation.svg",
  //   label: "Payout Automation",
  // },
];

const SideBarNavigation: React.FC = () => {
  const navigateTo = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);

  const handleMenuItemClick = (route: string) => {
    navigateTo(route);
  };

  useEffect(() => {
    setActiveMenuItem(window.location.pathname);
  }, []);

  const getMenuStyles = (route: string) =>
    [
      "flex",
      "items-center",
      "gap-4",
      "rounded-[12px]",
      "px-6",
      "py-2",
      "mx-1",
      "font-acumin",
      "cursor-pointer",
      route === activeMenuItem && "bg-[#F3EFFF]",
      "hover:bg-[#F3EFFF] hover:text-[#6F3FF2]",
    ].join(" ");
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 999,
        width: "230px",
        boxShadow: "0px 7px 29px 0px #64646F1A",
        overflowY: "auto",
      }}
      className="text-[#020D53] text-[14px] pt-4 font-medium px-1 font-acumin shadow-md shadow-[#64646F1A]  h-full sticky top-0 ..."
    >
      <div className="">
        <div className="font-semibold text-2xl text-[#6F3FF2] text-left m-2 mb-6 cursor-pointer">
          <a href="/">
            <img src="/assets/icons/zyppx-logo.svg" alt="ZyppX Logo" />
          </a>
        </div>
        <ul className="flex flex-col gap-2">
          {menuItems.map((menuItem) => (
            <li
              key={menuItem.route}
              className={getMenuStyles(menuItem.route)}
              onClick={() => handleMenuItemClick(menuItem.route)}
            >
              <img
                className={`h-4 ${
                  menuItem.route === activeMenuItem ? "filter-purple" : ""
                } hover:filter-purple`}
                src={menuItem.icon}
                alt=""
              />
              <span
                className={
                  menuItem.route === activeMenuItem
                    ? "text-[#6F3FF2] text-xs"
                    : "text-[#848D9C] text-xs"
                }
              >
                {menuItem.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBarNavigation;
