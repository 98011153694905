import React, { useState, useEffect } from "react";
import DynamicTable from "../../Layouts/DynamicTable";
import { Breadcrumbs, Card, Chip, Tooltip, Typography } from "@mui/material";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import SelectDynamicTable from "../../Layouts/SelectDynamicTable";
import {
  Bar,
  BarChart,
  CartesianGrid,
  DefaultTooltipContent,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import AllotmentCard from "../../Layouts/AllotmentCards";

const Allotment = () => {
  const [hubWiseData, setHubWiseData] = useState([]);
  const [technicianData, setTechnicianData] = useState([]);
  const [advisorData, setAdvisorData] = useState([]);
  const [advisorDetailsData, setAdvisorDetailsData] = useState([]);

  const tableColumns = [
    { label: "Rider ID", key: "fleetType" },
    { label: "Cycle ID", key: "fleetType" },
    { label: "QR Code", key: "fleetType" },
    { label: "Fleet Name", key: "fleetType" },
    { label: "User ID", key: "fleetType" },
    { label: "Name", key: "fleetType" },
    { label: "Mobile Number", key: "fleetType" },
    { label: "Rider Mode", key: "fleetType" },
    { label: "Hub Name", key: "mrfdReject" },
    { label: "Allotment Time", key: "mrfdReject" },
  ];
  const data = [
    {
      name: "Bengur Hobli",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "Chattarpur",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "Greater Noida",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "K.P Gr. Noida",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "Kurla",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "Mundka",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "SMPL Bajhgera",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "SMPL HSMR Hub",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "Sahibabad",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "Sector 17",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "Sector 69",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "SMPL 52",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "Smpl Mundka",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "Whitefield",
      alloted: 4000,
      unalloted: 2400,
    },
    {
      name: "Yelahanka",
      alloted: 4000,
      unalloted: 2400,
    },
  ];
  return (
    <>
      <div className="app-container">
        <SideBarNavigation />
        <div className="main-content">
          <div className="font-medium text-left text-[32px] font-acumin text-[#1E1E1E] font-acumin mt-8">
            Allotments/Submissions
            <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, mb: 4 }}>
              <Typography
                color="#717171"
                sx={{
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "AcuminRPro",
                }}
              >
                Allotments/Submissions
              </Typography>
            </Breadcrumbs>
          </div>
          <div className="flex mb-1 justify-between pr-5">
            <div className="w-full mr-2">
              <AllotmentCard
                heading="Total Fleets Allotted"
                backgroundColor="#6F3FF233"
                data="0"
                imgSrc="/assets/icons/allotment-icons/purple-scooter.svg"
                loading={false}
              />
            </div>
            <div className="w-full mr-2">
              <AllotmentCard
                heading="Total Fleets UnAllotted"
                backgroundColor="#F9493233"
                data="0"
                imgSrc="/assets/icons/allotment-icons/red-scooter.svg"
                loading={false}
              />
            </div>
            <div className="w-full">
              <AllotmentCard
                heading="Total Fleets In Zypp"
                backgroundColor="#04011C33"
                data="0"
                imgSrc="/assets/icons/allotment-icons/black-scooter.svg"
                loading={false}
              />
            </div>
          </div>
          <div className="flex mb-1 w-full pr-5">
            <Card
              sx={{
                boxShadow: "0px 7px 29px 0px #64646F33",
                mx: 1,
                my: 3,
                p: 2,
                width: "100%",
              }}
            >
              <div className="flex justify-between font-acumin font-semibold px-2 pb-3">
                ALL FLEETS REPORT
                <div className="flex items-center">
                  <div className="flex items-center text-[#171F21]">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="7" cy="7" r="7" fill="#6F3FF2" />
                    </svg>{" "}
                    <span className="ml-2 font-acumin">Allotted</span>
                  </div>
                  <div className="flex items-center text-[#171F21] ml-3">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="7" cy="7" r="7" fill="#D8CBFF" />
                    </svg>{" "}
                    <span className="ml-2 font-acumin">Unallotted</span>
                  </div>
                </div>
              </div>
              <div className="p-2">
                <ResponsiveContainer
                  width="100%"
                  height={325}
                  style={{ padding: 7 }}
                >
                  <BarChart data={data} margin={{ top: -5, bottom: 55 }}>
                    <CartesianGrid vertical={false} horizontal={false} />
                    <XAxis
                      dataKey="name"
                      angle={30}
                      dy={20}
                      tick={{
                        fontSize: 12,
                        fontWeight: "500",
                        fontFamily: "AcuminRPro",
                      }}
                    >
                      <Label
                        value="HUBS NAME ->"
                        dx={-10}
                        dy={60} // Adjust the vertical position
                      />
                    </XAxis>
                    <YAxis
                      tick={{
                        fontSize: 12,
                        fontWeight: "500",
                        fontFamily: "AcuminRPro",
                      }}
                    >
                      <Label
                        value="NO. OF CYCLES ->"
                        angle={-90}
                        dx={-35}
                        dy={15} // Adjust the vertical position
                      />
                    </YAxis>
                    <DefaultTooltipContent />
                    <Bar dataKey="alloted" fill="#D8CBFF" />
                    <Bar dataKey="unalloted" fill="#6F3FF2" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </div>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={tableColumns}
              data={technicianData}
              heading="FLEET ALLOTMENT DETAILS"
              dateIsVisible={false}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <SelectDynamicTable
              columns={tableColumns}
              data={advisorData}
              heading="FLEET SUBMITTED DETAILS"
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <SelectDynamicTable
              columns={tableColumns}
              data={advisorDetailsData}
              heading="FLEET ALLOTMENT TOKEN REPORT"
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Allotment;
