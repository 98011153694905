import React, { useState, useEffect } from "react";
import DynamicTable from "../../Layouts/DynamicTable";
import { Breadcrumbs, Card, Typography } from "@mui/material";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";

const PilotNPSReports = () => {
  const [hubWiseData, setHubWiseData] = useState([]);
  const [technicianData, setTechnicianData] = useState([]);
  const [advisorData, setAdvisorData] = useState([]);
  const [advisorDetailsData, setAdvisorDetailsData] = useState([]);

  const table1Columns = [
    { label: "User ID", key: "mobId" },
    { label: "Name", key: "hubName" },
    { label: "Mobile Number", key: "hubName" },
    { label: "Merchant", key: "mrfdTotal" },
    { label: "First Response Date", key: "mrfdTotal" },
    {
      label: "Did You Refer Someone If Yes Did You Get The Amount",
      key: "mrfdTotal",
    },
  ];
  const table2Columns = [
    { label: "User ID", key: "mobId" },
    { label: "Name", key: "hubName" },
    { label: "Mobile Number", key: "hubName" },
    { label: "Merchant", key: "mrfdTotal" },
    { label: "Profiling Category", key: "profile" },
    { label: "First Response Date", key: "mrfdTotal" },
  ];
  const table3Columns = [
    { label: "User ID", key: "mobId" },
    { label: "Name", key: "hubName" },
    { label: "Mobile Number", key: "hubName" },
    { label: "Merchant", key: "mrfdTotal" },
    { label: "Profiling Category", key: "profile" },
    { label: "First Response Date", key: "mrfdTotal" },
  ];

  return (
    <>
      <div className="app-container">
        <SideBarNavigation />
        <div className="main-content">
          <div className="font-medium text-left text-[32px] text-[#1E1E1E] font-acumin mt-8">
            Pilot NPS Reports
            <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, mb: 4 }}>
              <Typography
                color="#717171"
                sx={{
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "AcuminRPro",
                }}
              >
                Pilot NPS Reports
              </Typography>
            </Breadcrumbs>
          </div>

          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table1Columns}
              data={technicianData}
              heading="NPS RESPONSES REPORT"
              dateIsVisible={true}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table2Columns}
              data={advisorData}
              heading="NPS ALL USERS INFO REPORT"
              dateIsVisible={true}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table3Columns}
              data={advisorDetailsData}
              heading="NPS ALL USERS INFO REPORT"
              dateIsVisible={true}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default PilotNPSReports;
