import React, { useState, useEffect } from "react";
import DynamicTable from "../../Layouts/DynamicTable";
import { Breadcrumbs, Card, Chip, Typography } from "@mui/material";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";

const IOTReports = () => {
  const [hubWiseData, setHubWiseData] = useState([]);
  const [technicianData, setTechnicianData] = useState([]);
  const [advisorData, setAdvisorData] = useState([]);
  const [advisorDetailsData, setAdvisorDetailsData] = useState([]);

  const table1Columns = [
    { label: "IMEI Number", key: "hubName" },
    { label: "BLE Mac", key: "mrfdTotal" },
    { label: "MSI SDN", key: "mrfdTotal" },
    { label: "IOT Created At", key: "mrfdTotal" },
    { label: "Created By User ID", key: "mrfdTotal" },
    { label: "Created By Name", key: "mrfdTotal" },
    { label: "IOT Updated At", key: "mrfdTotal" },
    { label: "Updated By User ID", key: "mrfdTotal" },
    { label: "Updated By Name", key: "mrfdTotal" },
    { label: "IOT Type", key: "mrfdTotal" },
    { label: "Vendor", key: "mrfdTotal" },
    { label: "IOT Status", key: "mrfdTotal" },
    { label: "QR Code", key: "mrfdTotal" },
    { label: "Fleet Name", key: "mrfdTotal" },
  ];
  const table2Columns = [
    { label: "Cycle ID", key: "mobId" },
    { label: "QR Code", key: "hubName" },
    { label: "User ID", key: "hubName" },
    { label: "Name", key: "mrfdTotal" },
    { label: "Hub Name", key: "hubName" },
    { label: "User Type", key: "mrfdTotal" },
    { label: "Allotment Time", key: "mrfdTotal" },
    { label: "Connection Time", key: "mrfdTotal" },
    { label: "Connection Status", key: "mrfdTotal" },
    { label: "Disconnection Time", key: "mrfdTotal" },
  ];
  const table3Columns = [
    { label: "Cycle ID", key: "mobId" },
    { label: "QR Code", key: "hubName" },
    { label: "User ID", key: "hubName" },
    { label: "Name", key: "mrfdTotal" },
    { label: "Hub Name", key: "hubName" },
    { label: "User Type", key: "mrfdTotal" },
    { label: "Allotment Time", key: "mrfdTotal" },
    { label: "Connection Time", key: "mrfdTotal" },
    { label: "Connection Status", key: "mrfdTotal" },
    { label: "Disconnection Time", key: "mrfdTotal" },
  ];

  return (
    <>
      <div className="app-container">
        <SideBarNavigation />
        <div className="main-content">
          <div className="font-medium text-left text-[32px] text-[#1E1E1E] font-acumin mt-8">
            IOT Reports
            <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, mb: 4 }}>
              <Typography
                color="#717171"
                sx={{
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "AcuminRPro",
                }}
              >
                IOT Reports
              </Typography>
            </Breadcrumbs>
          </div>

          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table1Columns}
              data={technicianData}
              heading="IOT MASTER REPORT"
              dateIsVisible={false}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table2Columns}
              data={advisorData}
              heading="YESTERDAY'S ALLOTMENT IOT CONNECTED/DISCONNECTED STATUS REPORT"
              dateIsVisible={false}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table3Columns}
              data={advisorDetailsData}
              heading="ALL IOT WITH DISCONNECTED STATUS"
              dateIsVisible={false}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default IOTReports;
