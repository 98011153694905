import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { AuthenticationApi } from "../../../API/Authentication/LoginApi"; // Adjust to your import structure
import JSEncrypt from "jsencrypt";
import sha256 from "js-sha256";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const AuthLogin = () => {
  const [username, setUsername] = useState<string>("9720811804");
  const [password, setPassword] = useState<string>("123456");
  const [loginError, setLoginError] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();

  const hexToBytes = (hex: string) => {
    const bytes: number[] = [];
    for (let i = 0; i < hex.length; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return bytes;
  };

  const handleLogin = async () => {
    setLoader(true);
    try {
      const timestamp = String(Date.now());
      const hash = sha256.sha256(password + timestamp);
      const hashBytes = hexToBytes(hash);
      const hashBase64 = btoa(String.fromCharCode(...hashBytes));
      const data = `${password}:${timestamp}:${hashBase64}`;
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAg79k2m8PlGNIyJum2uzGqnhdjNqc90SF5FwoOlU7I6Hy6xh36K2qzw1Q5wLcfM+KojFy0xLzKf/u03Wm9S/SmbwvzlXaCq5uYEtVqJBxCz3Y+ZyTRlWyWDJki+OvGYqGbw1EmmKFt2SaOjsE/e+lo6KQpFZwGuaJ1ZKQjCbKRdmkOUAWIjK51a3r8kTnq7XIMf9iXaHX5qvTS/BMcRrXTQ5/JGeuy9OCaJ0S7YrSINZymd0puA0IH87QxDmnUoq+pJwo8l67MFwK7rLyCDSRGwdVY4kLQw8T25k53eoH6+ZUMLydkR4X/ryXYERIw/OwPWpoioHARKzyA/ARA2jkTQIDAQAB"
      );

      const encryptedData = encrypt.encrypt(data);
      if (!encryptedData) throw new Error("Encryption failed. Invalid data.");

      const response = await AuthenticationApi.api.onlineUserAuthentication(
        username,
        encryptedData
      );

      setLoader(false);
      if (response.data && response.data.token) {
        localStorage.setItem("token", response.data.token);
        navigate("/user-client-details");
      } else {
        setLoginError(response.message || "Access Denied");
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoader(false);
      setLoginError("Invalid Credentials or Network Error");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} className="w-full flex justify-between">
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Welcome!
          </Typography>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Log In to your account
          </Typography>
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight="bold">
              Enter Username
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter email address"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight="bold">
              Enter Password
            </Typography>
            <TextField
              fullWidth
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          {loader ? (
            <Button
              fullWidth
              size="large"
              variant="contained"
              sx={{
                backgroundColor: "#6F3FF2",
                "&:hover": { backgroundColor: "#6F3FF2" },
                borderRadius: "8px",
              }}
              disabled
            >
              <CircularProgress sx={{ color: "white" }} />
            </Button>
          ) : (
            <Button
              fullWidth
              size="large"
              variant="contained"
              sx={{
                backgroundColor: "#6F3FF2",
                "&:hover": { backgroundColor: "#6F3FF2" },
                borderRadius: "8px",
              }}
              type="submit"
            >
              Login
            </Button>
          )}
        </Grid>

        {loginError && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {loginError}
            </Typography>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default AuthLogin;
