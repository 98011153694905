import React, { useState } from "react";
import {
  Button,
  FormControl,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../DynamicTable/style.css";
import { CSVLink } from "react-csv";

const CycleStatusChangeTable = () => {
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [defaultStartDate, defaultEndDate] = getDefaultDateRange();
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [selectedStatus1, setSelectedStatus1] = useState<string>("1");
  const [selectedStatus2, setSelectedStatus2] = useState<string>("1");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState<any>(5);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const pageRange = 5;
  function getDefaultDateRange() {
    const today = new Date();
    const lastWeekStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const lastWeekEnd = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    return [lastWeekStart, lastWeekEnd];
  }
  const pageCount = Math.ceil(totalCount / rowsPerPage);
  const columns = [
    { label: "Ticket ID", key: "mobId" },
    { label: "Job ID", key: "hubName" },
    { label: "Job Created At", key: "mrfdReject" },
    { label: "Mechanic Name", key: "hubName" },
    { label: "QR Code", key: "mrfdSuccess" },
    { label: "Fleet Name", key: "hubName" },
    { label: "Hub Address", key: "mrfdTotal" },
    { label: "Part Issued At", key: "mrfdTotal" },
    { label: "Job Closed At", key: "mrfdTotal" },
    { label: "Time Taken", key: "mrfdTotal" },
  ];

  const handlePageChange = (newPage: number) => {
    if (pageCount === 1) {
      setPage(0);
    } else {
      const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
      setPage(newPageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (!selectedOptions.includes(selectedValue)) {
      setSelectedOptions([...selectedOptions, selectedValue]);
    }
  };
  const getOptionLabel = (value: number): string => {
    switch (value) {
      case 0:
        return "None Selected";
      case 1:
        return "Evosta Okhla Lectrix Dealership";
      case 2:
        return "Mundka West Delhi";
      default:
        return "";
    }
  };
  const handleChipDelete = (value: string) => {
    const updatedOptions = selectedOptions.filter((option) => option !== value);
    setSelectedOptions(updatedOptions);
  };
  return (
    <>
      <TableContainer>
        <div className="flex items-center justify-between p-3 border-slate-200">
          <div>
            <span className="font-semibold text-[#414141]">
              CYCLE STATUS CHANGE REPORT
            </span>
          </div>
          <div className="flex">
            <div className="flex">
              <div className="flex items-center mr-2">
                <div className="border-[2.5px] border-[#ECEEFF] py-[2px] rounded-2xl">
                  <div className="z-999">
                    <DatePicker
                      selectsRange={true}
                      className="text-black text-sm p-1 rounded-2xl"
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                      maxDate={new Date()}
                      dateFormat="yyyy/MM/dd"
                      onChange={(update: any) => {
                        setDateRange(update);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="border-2 border-[#ECEEFF] rounded-2xl px-2">
                <InputBase
                  type="number"
                  placeholder="Search"
                  // className="flex-grow"
                  inputProps={{ "aria-label": "search" }}
                  value={searchVal}
                  onChange={(search) => setSearchVal(search.target.value)}
                />
                <SearchIcon
                  style={{ color: "#727597" }}
                  className=" cursor-pointer ml-4"
                />
              </div>
              <div className="ml-2">
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    bgcolor: "#6F3FF2",
                    px: 2,
                    mr: 2,
                    "&:hover": { bgcolor: "#6F3FF2" },
                    borderRadius: "50px",
                    fontSize: "14px",
                  }}
                  disabled={searchVal !== null && searchVal.trim() !== ""}
                >
                  <CSVLink
                    data={data || []}
                    headers={columns}
                    filename={`Rider_info.csv`}
                  >
                    Export
                  </CSVLink>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center p-3 border-slate-200">
          <div className="border-2 border-[#ECEEFF] rounded-2xl px-2 mr-2">
            <label className="text-[#727597] text-sm font-normal">
              Status 1:{" "}
            </label>
            <select
              name="orderStatus"
              id="orderStatus"
              className="text-[#727597] text-sm font-normal font-acumin px-1 py-1 min-w-[150px]"
              value={selectedStatus1}
              onChange={(e) => setSelectedStatus1(e.target.value)}
            >
              <option value="1">On Maintenance Major</option>
            </select>
          </div>
          <div className="border-2 border-[#ECEEFF] rounded-2xl px-2 mr-2">
            <label className="text-[#727597] text-sm font-normal">
              Status 2:{" "}
            </label>
            <select
              name="orderStatus"
              id="orderStatus"
              className="text-[#727597] text-sm font-normal font-acumin px-1 py-1 min-w-[150px]"
              value={selectedStatus2}
              onChange={(e) => setSelectedStatus2(e.target.value)}
            >
              <option value="1">On Maintenance Major</option>
            </select>
          </div>
          <div className="flex items-center bg-white border-[#ECEEFF] border-2 border-solid rounded-2xl px-2">
            <div>
              <span className="text-[#727597] text-sm font-normal">
                Data Status:{" "}
              </span>
            </div>
            <FormControl>
              <select
                id="merchant"
                onChange={handleChange}
                value={selectedOptions}
                className="text-[#727597] text-sm font-normal font-acumin px-1 py-1 min-w-[150px]"
              >
                <option value="0" selected>
                  None Selected
                </option>
                <option value="1">Evosta Okhla Lectrix Dealership</option>
                <option value="2">Mundka West Delhi</option>
              </select>
            </FormControl>
          </div>
        </div>
        <div className="flex px-12 py-2">
          <div className="flex justify-evenly">
            {selectedOptions.map((value) => (
              <div
                key={value}
                className="flex items-center justify-between rounded-full px-2 py-1 bg-[#F3EFFF] text-[#6F3FF2] mr-3"
              >
                {getOptionLabel(parseInt(value, 10))}
                <button onClick={() => handleChipDelete(value)}>
                  <CloseIcon fontSize="small" sx={{ color: "#6F3FF2" }} />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div style={{ overflow: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align="left"
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        align={"left"}
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row[column.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
      <div className="pt-4 pb-2 pr-5">
        <div
          className="pagination"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="flex">
            <img
              src="/assets/icons/leftArrow.svg"
              alt=""
              onClick={() => handlePageChange(page - 1)}
            />
            {renderPageNumbers()}
            <img
              src="/assets/icons/rightArrow.svg"
              alt=""
              onClick={() => handlePageChange(page + 1)}
              className="ml-1 mr-6"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CycleStatusChangeTable;
