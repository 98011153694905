import React from "react";
import { Box, Grid2 } from "@mui/material";
import AuthLogin from "./Login";

function SignIn() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid2 container sx={{ flex: 1 }}>
        <Grid2 size={{ xs: false, sm: 4, md: 7 }} sx={{ height: "80%" }}>
          {/* <div className="flex items-center justify-between">
            <img
              src={"/assets/icons/Logo-zyppit.svg"}
              alt="Logo"
              className="ml-4 mt-4"
            />
          </div> */}
          <img src="/assets/icons/login-image.jpg" />
        </Grid2>
        <Grid2
          size={{ sm: 8, md: 5 }}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid2 size={{ sm: 8, md: 7 }}>
            <AuthLogin />
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default SignIn;
