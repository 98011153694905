import React, { useState, useEffect } from "react";
import DynamicTable from "../../Layouts/DynamicTable";
import { Breadcrumbs, Card, Typography } from "@mui/material";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";

const TokenReports = () => {
  const [hubWiseData, setHubWiseData] = useState([]);
  const [technicianData, setTechnicianData] = useState([]);
  const [advisorData, setAdvisorData] = useState([]);
  const [advisorDetailsData, setAdvisorDetailsData] = useState([]);

  const tableColumns = [
    { label: "User ID", key: "mobId" },
    { label: "Name", key: "hubName" },
    { label: "Mobile Number", key: "hubName" },
    { label: "Merchant", key: "mrfdTotal" },
    { label: "TL Name", key: "mrfdTotal" },
    {
      label: "Profiling Category",
      key: "mrfdTotal",
    },
    { label: "First Response Date", key: "mrfdTotal" },
  ];

  return (
    <>
      <div className="app-container">
        <SideBarNavigation />
        <div className="main-content">
          <div className="font-medium text-left text-[32px] text-[#1E1E1E] font-acumin mt-8">
            Token Reports
            <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, mb: 4 }}>
              <Typography
                color="#717171"
                sx={{
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "AcuminRPro",
                }}
              >
                Token Reports
              </Typography>
            </Breadcrumbs>
          </div>

          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={tableColumns}
              data={technicianData}
              heading="TOKEN ISSUED REPORT"
              dateIsVisible={true}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={tableColumns}
              data={advisorData}
              heading="TOKEN ISSUED REPORT"
              dateIsVisible={true}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={tableColumns}
              data={advisorDetailsData}
              heading="TOKEN ISSUED REPORT"
              dateIsVisible={true}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={tableColumns}
              data={advisorDetailsData}
              heading="TOKEN ISSUED REPORT"
              dateIsVisible={true}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default TokenReports;
