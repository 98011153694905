import React, { useState } from "react";
import {
  Button,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { CSVLink } from "react-csv";

interface TableColumn {
  label: string;
  key: string;
  align?: "left" | "right" | "center";
  render?: (value: any, row: any) => React.ReactNode;
}

interface TableProps {
  heading: string;
  columns: TableColumn[];
  data: any[];
  noDataMessage?: string;
  dateIsVisible: boolean;
}

const DynamicTable: React.FC<TableProps> = ({
  heading,
  columns,
  data,
  noDataMessage = "No data available",
  dateIsVisible,
}) => {
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [defaultStartDate, defaultEndDate] = getDefaultDateRange();
  const [dateRange, setDateRange] = useState([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [totalCount, setTotalCount] = useState<any>(5);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const pageRange = 5;
  function getDefaultDateRange() {
    const today = new Date();
    const lastWeekStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const lastWeekEnd = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    return [lastWeekStart, lastWeekEnd];
  }
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    if (pageCount === 1) {
      setPage(0);
    } else {
      const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
      setPage(newPageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`font-acumin pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };
  return (
    <>
      <TableContainer>
        <div className="flex items-center justify-between p-3 border-slate-200">
          <div>
            <span className="font-semibold font-acumin text-[#414141]">
              {heading}
            </span>
          </div>
          <div className="flex">
            <div className="flex">
              {dateIsVisible && (
                <div className="flex items-center mr-2">
                  <div className="border-[2.5px] border-[#ECEEFF] py-[2px] rounded-2xl">
                    <div className="z-999">
                      <DatePicker
                        selectsRange={true}
                        className="text-black text-sm p-1 rounded-2xl"
                        startDate={dateRange[0]}
                        endDate={dateRange[1]}
                        maxDate={new Date()}
                        dateFormat="yyyy/MM/dd"
                        onChange={(update: any) => {
                          setDateRange(update);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex">
              <div className="border-2 border-[#ECEEFF] rounded-2xl px-2">
                <InputBase
                  type="number"
                  placeholder="Search"
                  // className="flex-grow"
                  inputProps={{ "aria-label": "search" }}
                  value={searchVal}
                  onChange={(search) => setSearchVal(search.target.value)}
                />
                <SearchIcon
                  style={{ color: "#727597" }}
                  className=" cursor-pointer ml-4"
                />
              </div>
              <div className="ml-2">
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    bgcolor: "#6F3FF2",
                    px: 2,
                    "&:hover": { bgcolor: "#6F3FF2" },
                    borderRadius: "50px",
                    fontSize: "14px",
                  }}
                  disabled={searchVal !== null && searchVal.trim() !== ""}
                >
                  <CSVLink
                    data={data || []}
                    headers={columns}
                    filename={`Rider_info.csv`}
                  >
                    <span className="font-acumin">Export</span>
                  </CSVLink>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ overflow: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align || "left"}
                    className="font-acumin"
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "AcuminRPro",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        align={column.align || "left"}
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "AcuminRPro",
                        }}
                      >
                        {column.render
                          ? column.render(row[column.key], row)
                          : row[column.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    align="center"
                    style={{ fontFamily: "AcuminRPro" }}
                  >
                    {noDataMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
      <div className="pt-4 pb-2 pr-5">
        <div
          className="pagination font-acumin"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="flex">
            <img
              src="/assets/icons/leftArrow.svg"
              alt=""
              onClick={() => handlePageChange(page - 1)}
            />
            {renderPageNumbers()}
            <img
              src="/assets/icons/rightArrow.svg"
              alt=""
              onClick={() => handlePageChange(page + 1)}
              className="ml-1 mr-6"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicTable;
