import axios, { AxiosInstance, AxiosResponse } from "axios";

import { LoginResponse } from "../../@Types/DRO/LoginResponse";

export class AuthenticationApi {
  public static api = new AuthenticationApi();
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: "https://stag-ood.bcykal.com/mobycy",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getToken() {
    return localStorage.getItem("token");
  }

  async onlineUserAuthentication(
    userName: string,
    password: string
  ): Promise<LoginResponse> {
    try {
      const response: AxiosResponse<LoginResponse> = await this.instance.get(
        `/auth/login`,
        {
          params: {
            userName,
            password,
          },
        }
      );
      if (response.data && response.data.code === 2000) {
        localStorage.setItem("token", response.data.data.token);
      }
      return response.data;
    } catch (error) {
      throw new Error("Login failed");
    }
  }
}
