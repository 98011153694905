import React from "react";
import { Paper, Box, CircularProgress } from "@mui/material";

export interface DashboardCardProps {
  heading: string;
  color: string;
  data: number | string;
  imgSrc: string;
  loading: boolean;
}
const DashboardJobCard: React.FC<DashboardCardProps> = ({
  heading,
  color,
  data,
  imgSrc,
  loading,
}) => {
  return (
    <Box
      sx={{
        "& > :not(style)": {
          m: 1,
          width: "100%",
          height: "100%",
        },
      }}
    >
      <Paper
        elevation={6}
        sx={{
          boxShadow: "0px 7px 29px 0px #64646F33",
          borderRadius: "8px",
        }}
      >
        <div className="flex items-start">
          <div className="mt-2 ml-3 mb-2">
            <svg
              width="3"
              height="75"
              viewBox="0 0 4 114"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2L1.99999 110"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div className="w-full">
            <div className="flex justify-between items-start pt-2 px-2 text-wrap">
              <p className="text-[#727597] font-semibold text-sm font-acumin text-wrap">
                {heading}
              </p>
            </div>
            {loading ? (
              <div className="flex items-center px-2">
                <CircularProgress sx={{ color: color }} />
              </div>
            ) : (
              <>
                <div className="flex justify-between items-end pt-3 px-2 text-wrap">
                  <p className="px-2 text-left font-acumin text-4xl font-semibold text-[#1E1E1E]">
                    {data}
                  </p>
                  <img src={imgSrc} alt="" style={{ width: "20px" }} />
                </div>
              </>
            )}
          </div>
        </div>
      </Paper>
    </Box>
  );
};

export default DashboardJobCard;
