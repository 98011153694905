import React from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { Router } from "./Router/router";
import "@fontsource/space-grotesk";

function App() {
  return (
    <div className="App">
      <div>
        <Routes>
          {Router.map((el, index) => {
            let shouldRedirect = false;
            let redirectUrl = "/";
            if (el.shouldRedirect) {
              shouldRedirect = el.shouldRedirect();
            }
            if (el.redirectUrl) {
              redirectUrl = el.redirectUrl;
            }
            return (
              <Route
                key={index}
                path={el.path}
                element={
                  <ProtectedRoute
                    shouldRedirect={shouldRedirect}
                    url={redirectUrl}
                    child={el.element}
                  ></ProtectedRoute>
                }
              >
                {el.children &&
                  el.children.map((nested, id) => {
                    return (
                      <Route
                        key={id}
                        path={nested.path}
                        element={
                          <ProtectedRoute
                            shouldRedirect={shouldRedirect}
                            url={redirectUrl}
                            child={nested.element}
                          />
                        }
                      />
                    );
                  })}
              </Route>
            );
          })}
        </Routes>
      </div>
    </div>
  );
}
interface ProtectedRouteProps {
  child: Element | JSX.Element;
  shouldRedirect: boolean;
  url: string;
  children?: any;
}

function ProtectedRoute({
  child,
  shouldRedirect,
  url = "/",
}: ProtectedRouteProps): JSX.Element {
  if (shouldRedirect) {
    return <Navigate to={url} />;
  }
  return <>{child}</>;
}

export default App;
