import Allotment from "../Components/UI/Allotments";
import HubEfficiencyReports from "../Components/UI/HubEfficiencyReports";
import IOTReports from "../Components/UI/IOTReports";
import JobCard from "../Components/UI/JobCard";
import { Navigate } from "react-router-dom";
import PayoutAutomation from "../Components/UI/PayoutAutomation";
import PilotNPSReports from "../Components/UI/PilotNPSReports";
import SignIn from "../Components/UI/Login";
import TLReports from "../Components/UI/TLReports";
import Ticketing from "../Components/UI/Ticketing";
import TokenReports from "../Components/UI/TokenReports";
import UserClientDetails from "../Components/UI/UserClientDetails";
import WalletIndividualReports from "../Components/UI/WalletIndividualReports";
import WalletReports from "../Components/UI/WalletReports";

interface Routes {
  path: string;
  element: Element | JSX.Element;
  redirectUrl?: string;
  shouldRedirect?: () => boolean;
  children?: Array<Routes>;
}

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = localStorage.getItem("token");
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const CommonRoutes: Array<Routes> = [
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/user-client-details",
    element: (
      <RequireAuth>
        <UserClientDetails />
      </RequireAuth>
    ),
  },
  {
    path: "/ticketing",
    element: (
      <RequireAuth>
        <Ticketing />
      </RequireAuth>
    ),
  },
  {
    path: "/job-card",
    element: (
      <RequireAuth>
        <JobCard />
      </RequireAuth>
    ),
  },
  {
    path: "/tl-report",
    element: (
      <RequireAuth>
        <TLReports />
      </RequireAuth>
    ),
  },
  {
    path: "/allotments",
    element: (
      <RequireAuth>
        <Allotment />
      </RequireAuth>
    ),
  },
  {
    path: "/iot-report",
    element: (
      <RequireAuth>
        <IOTReports />
      </RequireAuth>
    ),
  },
  {
    path: "/pilot-nps-report",
    element: (
      <RequireAuth>
        <PilotNPSReports />
      </RequireAuth>
    ),
  },
  {
    path: "/token-report",
    element: (
      <RequireAuth>
        <TokenReports />
      </RequireAuth>
    ),
  },
  {
    path: "/hub-efficiency-report",
    element: (
      <RequireAuth>
        <HubEfficiencyReports />
      </RequireAuth>
    ),
  },
  {
    path: "/payout-automation",
    element: (
      <RequireAuth>
        <PayoutAutomation />
      </RequireAuth>
    ),
  },
  {
    path: "/wallet-report",
    element: (
      <RequireAuth>
        <WalletReports />
      </RequireAuth>
    ),
  },
  {
    path: "/wallet-individual-report",
    element: (
      <RequireAuth>
        <WalletIndividualReports />
      </RequireAuth>
    ),
  },
];
export const Router: Array<Routes> = [
  ...CommonRoutes,
  /**
   *@description
   * Url to Embeded in Iframe
   *
   */
  // {
  //   path: "", // test route
  //   element: <Header />,
  //   children: [
  //     ...CommonRoutes,
  //     {
  //       path: "*", // test route
  //       element: <div>NOT FOUND</div>,
  //     },
  //   ],
  // },
];
