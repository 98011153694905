import React, { useState, useEffect } from "react";
import DynamicTable from "../../Layouts/DynamicTable";
import { Breadcrumbs, Card, Chip, Typography } from "@mui/material";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import DashboardJobCard from "../../Layouts/DashboardJobCard";
import CycleStatusChangeTable from "../../Layouts/CycleStatusChangeTable";

const JobCard = () => {
  const [hubWiseData, setHubWiseData] = useState([]);
  const [technicianData, setTechnicianData] = useState([]);
  const [advisorData, setAdvisorData] = useState([]);
  const [advisorDetailsData, setAdvisorDetailsData] = useState([]);

  const table1Columns = [
    { label: "Job ID", key: "fleetType" },
    { label: "Mechanic ID", key: "fleetType" },
    { label: "Updated By", key: "fleetType" },
    { label: "Parking Area Name", key: "fleetType" },
    { label: "Job State", key: "fleetType" },
    { label: "Assignment Type", key: "fleetType" },
    { label: "Created At", key: "fleetType" },
  ];
  const table2Columns = [
    { label: "Pending Count", key: "mobId" },
    { label: "WIP Count", key: "hubName" },
    { label: "Hub Assigned Count", key: "mrfdReject" },
    { label: "Forced Assigned Count", key: "hubName" },
    { label: "Closed Count", key: "hubName" },
  ];
  const table3Columns = [
    { label: "Ticket ID", key: "mobId" },
    { label: "Job ID", key: "hubName" },
    { label: "Job Created At", key: "mrfdReject" },
    { label: "Mechanic Name", key: "hubName" },
    { label: "QR Code", key: "mrfdSuccess" },
    { label: "Fleet Name", key: "hubName" },
    { label: "Hub Address", key: "mrfdTotal" },
    { label: "Part Issued At", key: "mrfdTotal" },
    { label: "Job Closed At", key: "mrfdTotal" },
    { label: "Time Taken", key: "mrfdTotal" },
  ];
  return (
    <>
      <div className="app-container">
        <SideBarNavigation />
        <div className="main-content">
          <div className="font-medium text-left text-[32px] text-[#1E1E1E] font-acumin mt-8">
            Job Card
            <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, mb: 4 }}>
              <Typography
                color="#717171"
                sx={{
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "AcuminRPro",
                }}
              >
                Job Card
              </Typography>
            </Breadcrumbs>
          </div>
          <div className="flex mb-1 justify-between pr-5">
            <div className="w-full mr-2">
              <DashboardJobCard
                heading="Forced Assigned Count"
                color="#6F3FF2"
                data="0"
                imgSrc="/assets/icons/job-card-icons/purple-scooter.svg"
                loading={false}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardJobCard
                heading="Pending Count"
                color="#F94932"
                data="0"
                imgSrc="/assets/icons/job-card-icons/red-scooter.svg"
                loading={false}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardJobCard
                heading="WIP Count"
                color="#FFB846"
                data="0"
                imgSrc="/assets/icons/job-card-icons/yellow-scooter.svg"
                loading={false}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardJobCard
                heading="Hub Assigned Count"
                color="#1D88FF"
                data="0"
                imgSrc="/assets/icons/job-card-icons/blue-scooter.svg"
                loading={false}
              />
            </div>
            <div className="w-full">
              <DashboardJobCard
                heading="Closed Count"
                color="#06B681"
                data="0"
                imgSrc="/assets/icons/job-card-icons/green-scooter.svg"
                loading={false}
              />
            </div>
          </div>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table1Columns}
              data={technicianData}
              heading="ALL JOB STATUS"
              dateIsVisible={false}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table2Columns}
              data={advisorData}
              heading="ALL JOB SUMMARY"
              dateIsVisible={false}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table3Columns}
              data={advisorDetailsData}
              heading="PART ISSUED TO CLOSED TIME REPORT"
              dateIsVisible={true}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <CycleStatusChangeTable />
          </Card>
        </div>
      </div>
    </>
  );
};

export default JobCard;
