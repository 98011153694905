import { Breadcrumbs, Button, Card, Chip, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import DynamicTable from "../../Layouts/DynamicTable";
import { LogoutRounded } from "@mui/icons-material";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";

const UserClientDetails = () => {
  const [hubWiseData, setHubWiseData] = useState([]);
  const [technicianData, setTechnicianData] = useState([]);
  const [advisorData, setAdvisorData] = useState([]);
  const [advisorDetailsData, setAdvisorDetailsData] = useState([]);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const table1Columns = [
    { label: "User ID", key: "mobId" },
    { label: "Rider Name", key: "hubName" },
    { label: "QR Code", key: "mrfdSuccess" },
    { label: "Hub Name", key: "mrfdReject" },
    { label: "Fleet Mode", key: "mrfdTotal" },
    { label: "TL ID", key: "mrfdTotal" },
    { label: "TL Name", key: "mrfdTotal" },
    { label: "Modified DB", key: "mrfdTotal" },
    { label: "Merchant Approved", key: "mrfdTotal" },
    { label: "Merchant Approval Time", key: "mrfdTotal" },
    { label: "Merchant Approved by Number", key: "mrfdTotal" },
    { label: "Alloted By ID", key: "mrfdTotal" },
    { label: "Alloted By", key: "mrfdTotal" },
    { label: "City Name", key: "mrfdTotal" },
    { label: "Client User ID", key: "mrfdTotal" },
    { label: "Shop Name", key: "mrfdTotal" },
    { label: "Merchant Name", key: "mrfdTotal" },
    { label: "Helmet", key: "mrfdTotal" },
    { label: "T Shirt", key: "mrfdTotal" },
    { label: "Profiling Category", key: "mrfdTotal" },
    { label: "Ignition Key", key: "mrfdTotal" },
    { label: "Seat Lock Key", key: "mrfdTotal" },
  ];
  const table2Columns = [
    { label: "User ID", key: "mobId" },
    { label: "Rider Name", key: "hubName" },
    { label: "Mobile Number", key: "hubName" },
    { label: "Fleet Name", key: "hubName" },
    { label: "QR Code", key: "mrfdSuccess" },
    { label: "Hub Name", key: "mrfdReject" },
    { label: "Fleet Mode", key: "mrfdTotal" },
    { label: "TL ID", key: "mrfdTotal" },
    { label: "TL Name", key: "mrfdTotal" },
    { label: "Modified DB", key: "mrfdTotal" },
    { label: "Merchant Approved", key: "mrfdTotal" },
    { label: "Merchant Approval Time", key: "mrfdTotal" },
    { label: "Merchant Approved by Number", key: "mrfdTotal" },
    { label: "Alloted By ID", key: "mrfdTotal" },
    { label: "Alloted By", key: "mrfdTotal" },
    { label: "City Name", key: "mrfdTotal" },
    { label: "Client User ID", key: "mrfdTotal" },
    { label: "Shop Name", key: "mrfdTotal" },
    { label: "Last Latitude", key: "lat" },
    { label: "Last Longitude", key: "long" },
    { label: "Merchant Name", key: "mrfdTotal" },
    { label: "Helmet", key: "mrfdTotal" },
    { label: "T Shirt", key: "mrfdTotal" },
    { label: "Profiling Category", key: "mrfdTotal" },
    { label: "Ignition Key", key: "mrfdTotal" },
    { label: "Seat Lock Key", key: "mrfdTotal" },
  ];
  const table3Columns = [
    { label: "User ID", key: "mobId" },
    { label: "Rider Name", key: "hubName" },
    { label: "Mobile Number", key: "hubName" },
    { label: "Fleet Name", key: "hubName" },
    { label: "QR Code", key: "mrfdSuccess" },
    { label: "User Type", key: "mrfdToPreRfd" },
    { label: "Plan Description", key: "mrfdToMok" },
    { label: "Package Price", key: "aging" },
    { label: "Plan Expiry Date", key: "aging" },
    { label: "Shop Name", key: "mrfdTotal" },
    { label: "Merchant Name", key: "mrfdTotal" },
  ];
  const table4Columns = [
    { label: "User ID", key: "fleetType" },
    { label: "Rider Name", key: "hubName" },
    { label: "Mobile Number", key: "fleetCount" },
    {
      label: "Request Status",
      key: "vehicleStatus",
    },
    { label: "Shop ID", key: "submissionDate" },
    { label: "Requested Client", key: "vehicleAge" },
    { label: "Requested Time", key: "currentAge" },
  ];
  const table5Columns = [
    { label: "User ID", key: "fleetType" },
    { label: "Rider Name", key: "hubName" },
    { label: "Mobile Number", key: "fleetCount" },
    {
      label: "Relative Info",
      key: "vehicleStatus",
    },
    { label: "Relative Name", key: "submissionDate" },
    { label: "Rider Gender", key: "vehicleAge" },
    { label: "Rider Start Time", key: "currentAge" },
    { label: "Ride Status", key: "currentAge" },
    { label: "Aadhar Number", key: "currentAge" },
    { label: "PAN Number", key: "currentAge" },
    { label: "Account Number", key: "currentAge" },
    { label: "Bank Name", key: "currentAge" },
    { label: "IFSC Code", key: "currentAge" },
    { label: "TL Name", key: "currentAge" },
    { label: "TL Mobile Number", key: "currentAge" },
  ];
  const table6Columns = [
    { label: "User ID", key: "fleetType" },
    { label: "Rider Name", key: "fleetType" },
    { label: "Mobile Number", key: "fleetType" },
    { label: "TL Name", key: "fleetType" },
  ];
  return (
    <>
      <div className="app-container">
        <SideBarNavigation />
        <div className="main-content">
          <div className="font-medium text-left text-[32px] text-[#1E1E1E] font-acumin mt-8 flex justify-between items-center">
            User Client Details
            <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, mb: 4 }}>
              <LogoutRounded
                className="cursor-pointer"
                onClick={handleLogout}
              ></LogoutRounded>
              {/* <Typography
                color="#717171"
                sx={{
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "AcuminRPro",
                }}
              >
                User Client Details
              </Typography> */}
            </Breadcrumbs>
          </div>

          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table1Columns}
              data={technicianData}
              heading="CLIENT ID MAPPING AGAINST USERS WITH ON BOARDING STATUS"
              dateIsVisible={false}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table2Columns}
              data={advisorData}
              heading="CLIENT ID MAPPING AGAINST CHURNED USERS WITH ON BOARDING STATUS"
              dateIsVisible={false}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table3Columns}
              data={advisorDetailsData}
              heading="RENTAL TO PILOT USERS"
              dateIsVisible={false}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table4Columns}
              data={hubWiseData}
              heading="PENDING MERCHANT REQUESTS"
              dateIsVisible={false}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table5Columns}
              data={hubWiseData}
              heading="ZYPP FEMALE RIDERS"
              dateIsVisible={false}
            />
          </Card>
          <Card sx={{ boxShadow: "0px 7px 29px 0px #64646F33", mx: 1, my: 3 }}>
            <DynamicTable
              columns={table6Columns}
              data={hubWiseData}
              heading="PENDING RIDER MAPPING REQUEST"
              dateIsVisible={false}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default UserClientDetails;
