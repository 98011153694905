import React from "react";
import { Paper, Box, CircularProgress } from "@mui/material";

const extractStrokeColor = (backgroundColor: string) => {
  const rgbColor = backgroundColor.slice(0, -2);
  return rgbColor;
};
interface DashboardCardProps {
  heading: string;
  backgroundColor: string;
  data: number | string;
  imgSrc: string;
  loading: boolean;
}

const AllotmentCard: React.FC<DashboardCardProps> = ({
  heading,
  backgroundColor,
  data,
  imgSrc,
  loading,
}) => {
  const strokeColor = extractStrokeColor(backgroundColor);

  return (
    <Box
      sx={{
        "& > :not(style)": {
          m: 1,
          width: "100%",
          height: "100%",
        },
      }}
    >
      <Paper
        elevation={6}
        sx={{
          boxShadow: "0px 7px 29px 0px #64646F33",
          borderRadius: "8px",
          p: 1,
        }}
      >
        <div className="flex items-center">
          <div className="ml-1 rounded-sm">
            <img
              src={imgSrc}
              alt=""
              className="p-2 py-3 rounded-sm"
              style={{ backgroundColor: backgroundColor, width: "140px" }}
            />
          </div>
          <div className="mt-2 px-2 mb-2">
            <svg
              width="4"
              height="75"
              viewBox="0 0 4 119"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2L1.99999 117"
                stroke={strokeColor}
                strokeWidth="4"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div className="w-full">
            <div className="flex justify-between items-start pb-1 px-2 text-wrap">
              <p className="text-[#727597] text-sm font-medium font-acumin text-wrap">
                {heading}
              </p>
            </div>
            {loading ? (
              <div className="flex items-center px-2">
                <CircularProgress sx={{ color: strokeColor }} />
              </div>
            ) : (
              <>
                <p className="px-2 text-left font-acumin text-5xl font-semibold text-[#1E1E1E]">
                  {data}
                </p>
              </>
            )}
          </div>
        </div>
      </Paper>
    </Box>
  );
};

export default AllotmentCard;
